/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Implement fixed position for desktop's navigation view and appropriate height.
 * Contain "-small" modifier for hiding part of the navigation.
 */

@media all and (min-height: $topnav-fixed-height) and (min-width: $topnav-breakpoint) {
	.topnav {
		position: fixed;
		top: 0; left: 0;
		width: 100%;
	}

	.topnav-offset {
		padding-top: $topnav-height-second + $topnav-height-primary;
	}

	.topnav.-small {
		.topnav-second { margin-top: -$topnav-height-second; }
		.topnav-cart { margin-top: $topnav-height-second; }
	}
}