/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 * 
 * Bower component: http://iamceege.github.io/tooltipster/
 * (custom theme - http://iamceege.github.io/tooltipster/#styling)
 * 
 * Plugin default tooltip structure:
 *
 * <div class="tooltipster-base tooltipster-sidetip tooltip-custom tooltipster-top tooltipster-fade tooltipster-show" id="tooltipster-881447"
 *      style="z-index: 9999999; left: 38px; top: 324px; height: 42px; width: 272.047px; animation-duration: 350ms; transition-duration: 350ms;">
 *     
 *     <div class="tooltipster-box">
 *         <div class="tooltipster-content">This is my image's tooltip message!</div>
 *     </div>
 *     
 *     <div class="tooltipster-arrow" style="left: 137px;">
 *         <div class="tooltipster-arrow-uncropped">
 *             <div class="tooltipster-arrow-border"></div>
 *             <div class="tooltipster-arrow-background"></div>
 *         </div>
 *     </div>
 * </div>
 */

.tooltip-custom .tooltipster-box {
	background: $gray-dark;
	border: none;
	margin: $space-sm;
}

.tooltip-custom .tooltipster-box .tooltipster-content {
	@extend .font-tiny;
	padding: $space-xs $space-sm;
}

.tooltip-custom.tooltipster-top  .tooltipster-arrow-border { border-top-color: transparent; }
.tooltip-custom.tooltipster-right  .tooltipster-arrow-border { border-right-color: transparent; }
.tooltip-custom.tooltipster-bottom  .tooltipster-arrow-border { border-bottom-color: transparent; }
.tooltip-custom.tooltipster-left  .tooltipster-arrow-border { border-left-color: transparent; }

.tooltip-custom.tooltipster-top  .tooltipster-arrow-background { border-top-color: $gray-dark; }
.tooltip-custom.tooltipster-right  .tooltipster-arrow-background { border-right-color: $gray-dark; }
.tooltip-custom.tooltipster-bottom  .tooltipster-arrow-background { border-bottom-color: $gray-dark; }
.tooltip-custom.tooltipster-left  .tooltipster-arrow-background { border-left-color: $gray-dark; }