.server-item {
  .pricing-item-name {
    padding: 5px 16px 0;
  }
}

@media (min-width: $md) {
  .server-item {
    min-height: 570px;
    position: relative;
  }
  .server-pricing-bottom {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
  }

  #serwerownia {
    background: url('../img/market/banner/servers.jpg') no-repeat  bottom center;
    background-size: cover;
    & > div {
      display: block;
      height: 430px;
      z-index: 1;
      color: white;
    }

    &:before {
      content: none;
    }
  }
}

.pricing-vps {
  min-height: 350px;
  .pricing-item {
    min-height: 300px;
  }
}
