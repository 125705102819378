/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.pagination {
	overflow-x: auto;
	white-space: nowrap;
}

.pagination-item {
	@extend .link;
	@extend .pls, .prs;
	font-size: $font-size-normal;
}

span.pagination-item,
span.pagination-item:hover {
	cursor: default;
	color: $gray-neutral;
}

.pagination-item.-current {
	@extend .font-dark;	
}