/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.topnav-primary {
	height: $topnav-height-primary;
	position: relative;
}

.topnav-primary .topnav-brand {
	float: left;
	font-size: 0rem;
	line-height: $topnav-height-primary;
	max-height: $topnav-height-primary;

	img {
		font-size: 0rem;
		vertical-align: middle;
	}
}

.topnav-primary .topnav-group {
	float: right;
	vertical-align: top;
	display: inline-block;
	margin-right: $topnav-height-second; // Make empty space for the cart link/icon
	line-height: $topnav-height-primary;

	> li { display: inline-block; }
}

.topnav-primary .topnav-link {
	font-weight: $font-weight-bold;
	color: $gray-neutral;
	text-transform: uppercase;
	display: block;
	padding: $space-sm;
	line-height: normal;

	&:hover { color: $gray-dark; }
	&.active { color: $primary; }
}