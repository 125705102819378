/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Summary:
 *
 *	1. Base
 *	2. Elements
 *	3. Collections
 *	4. Pages
 */

/* ==========================================================================
   1. Base
   ========================================================================== */

@import 'base/colors';
@import 'base/config';
@import 'base/mixins';
@import 'base/reset';

/* ==========================================================================
   2. Elements
   ========================================================================== */

@import 'elements/helpers/spacing';
@import 'elements/helpers/typography';
@import 'elements/helpers/responsive';
@import 'elements/helpers/shape';
@import 'elements/helpers/position';
@import 'elements/helpers/loader';
@import 'elements/clearfix';
@import 'elements/button';
@import 'elements/link';
@import 'elements/container';
@import 'elements/material-icons';
@import 'elements/code';
@import 'elements/badge';
@import 'elements/markdown';
@import 'elements/corner-ribbon';
@import 'elements/sticky-banner';

/* ==========================================================================
   3. Collections
   ========================================================================== */

@import 'collections/article';
@import 'collections/grid';
@import 'collections/blog';
@import 'collections/topnav/topnav';
@import 'collections/footer';
@import 'collections/image';
@import 'collections/banner';
@import 'collections/form';
@import 'collections/tooltip';
@import 'collections/table';
@import 'collections/menu';
@import 'collections/panel';
@import 'collections/pricing';
@import 'collections/tabs';
@import 'collections/promo';
@import 'collections/dropdown';
@import 'collections/breadcrumb';
@import 'collections/pagination';
@import 'collections/sidenav';
@import 'collections/toast';
@import 'collections/modal';
@import 'collections/dropdown-list';

/* ==========================================================================
   4. Pages
   ========================================================================== */

@import 'pages/package';
@import 'pages/products';
@import 'pages/wizard';
@import 'pages/search';
@import 'pages/login';
@import 'pages/feature';
@import 'pages/php7';
@import 'pages/table-hosting-packages';
@import 'pages/user-item';
@import 'pages/transfer';
@import 'pages/regulaminy';
@import 'pages/servers';
@import 'pages/creator';
@import 'pages/promotions';
