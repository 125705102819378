/**
 * Version: 2.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Version:
 *
 * 1.0.1 - fix style if active tab is also a "<a>" tag
 */
 
.tabs-links {
	@extend .clearfix;
	display: block;
	position: relative;
	white-space: nowrap;
	overflow-y: hidden;
	-webkit-overflow-scrolling-x: touch;
}

.tabs-link {
	@extend .font-bold;
	@extend .font-small;
	display: inline-block;
	line-height: $tabs-link-height;
	padding-left: $tabs-h-padding;
	padding-right: $tabs-h-padding;
	cursor: pointer;

	&.-active {
		background: white;
		color: $font-color;
	}
}

.tabs-content {
	@extend .clearfix;
	background: white;
	padding-left: $tabs-h-padding;
	padding-right: $tabs-h-padding;
}
