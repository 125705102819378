.single-promo-item {

  .photo-link {
    img {
      opacity: 0.5;
      max-width: 100%;
      transition: all 0.5s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }

  }

  &:nth-child(even) {
    .grid {
      flex-direction: row-reverse;
    }
  }

}

@media (min-width: $md) {
  .single-promo-item {
    padding: 40px;
    .photo-link {

    }

    .grid {
      display: flex;
      &:last-child {
        align-items: center;
      }
    }

    &:nth-child(even) {
      .grid {
        flex-direction: row-reverse;
        & > div {
          &:first-child {
            text-align: right;
          }
        }
      }
    }

  }
}