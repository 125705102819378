/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Summary:
 *
 *  1. Basic
 *  2. Modifiers
 *     - 2.1. Warning
 *     - 2.2. Error
 *     - 2.3. Success
 *     - 2.4. Info
 *     - 2.5. No-js
 */

/* ==========================================================================
   1. Basic
   ========================================================================== */

.toast {
	position: fixed;
	bottom: 0; right: 0;
	max-width: 100%;
	z-index: 120;
}

.toast-offset {
	margin: $toast-offset;
	background: white;
	box-shadow: 0 1px 3px rgba(black, 0.3);
	display: table;
}

.toast-icon {
	@extend .font-large;
	display: table-cell;
	background: $primary;
	vertical-align: middle;
	width: $toast-icon-width;
	color: white;
	text-align: center;
}

.toast-content {
	display: table-cell;
	padding: $space-xs $space-md;
	padding-right: $space-lg;
	border-top: 3px solid $primary;
	max-width: $toast-prefered-width;
	position: relative;

	.link {
		height: auto;
		line-height: $font-height;
	}
}

.toast-close {
	@extend .font-dark;
	position: absolute;
	top: 0; right: 0;
	padding: $space-xs $space-sm;
	cursor: pointer;
}

/* ==========================================================================
   2. Modifiers
   ========================================================================== */

/* 2.1. Warning
   ========================================================================== */

.toast.-warning {
	.toast-icon { background: $orange; }
	.toast-content { border-top-color: $orange; }
}

/* 2.2. Error
   ========================================================================== */

.toast.-error {
	.toast-icon { background: $orange; }
	.toast-content { border-top-color: $orange; }
}

/* 2.3. Success
   ========================================================================== */

.toast.-success {
	.toast-icon { background: $green; }
	.toast-content { border-top-color: $green; }
}

/* 2.4. Info
   ========================================================================== */

.toast.-info {
	.toast-icon { background: $blue; }
	.toast-content { border-top-color: $blue; }
}

/* 2.5. No-js
   ========================================================================== */

.toast.-no-js {
	display: none;
	.toast-close { display: none; }
}

.no-js .toast.-no-js { display: block !important; }