/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * @param $n - width from range <1, $columns>
 * @param $columns - number of columns (> 0)
 */

@mixin col-base($gutter: $grid-gutter) {
	float: left;
	display: block;
	min-height: 1px;
	padding: 0 ($gutter / 2);
}

@mixin col-size($n, $columns) {
	width: ($n / $columns) * 100%;
}

@mixin col($n, $columns) {
	@include col-base;
	@include col-size($n, $columns);
}