/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.p-wizard {
	background-color: #235736;
	background-image: url(../img/icons/dot-dark.png), url(../img/market/banner/forest.jpg);
	background-repeat: repeat, no-repeat;
	background-position: center center;
	background-size: 2px, cover;
	color: white;

	h1:after { background-color: rgba(white, 0.1); }
}

.p-wizard-sample {
	font-size: 0rem;
	box-shadow: 0 2px 6px rgba(black, 0.6);
	max-width: 100%;
}

.p-wizard-guide {
	@extend .text-nowrap;
	@extend .text-center;
	
	@include gte(lg) { line-height: 200px; }
}

.p-wizard-step {
	@extend .font-bold;
	@extend .text-upper;
	@extend .text-center;
	display: inline-block;
	vertical-align: middle;
	line-height: normal;

	.material-icons {
		@extend .mbs;
		@extend .font-giant;
	}
}

.p-wizard-next {
	@extend .mlm, .mrm;
	@extend .font-giant;
}