/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Summary:
 *
 *	1. Row & Columns
 *     - 1.1. Widths
 *	2. Modifiers
 *     - 2.1. -no-gutter
 *
 * Examples:
 *
 * <div class="grid">
 *     <div class="xs-4"> width = 4/12 = 1/3 </div>
 *     <div class="xs-2"> width = 2/12 = 1/6 </div>
 *     <div class="xs-6"> width = 6/12 = 1/2 </div>
 * </div>
 *
 * <div class="grid -no-gutter">
 *     <div class="xs-12 sm-6"> width 1/1 or 1/2 </div>
 *     <div class="xs-12 sm-6"> width 1/1 or 1/2 </div>
 *     <div class="break xs-hide"></div>
 *     <div class="xs-12"> full width </div>
 * </div>
 */

/* ==========================================================================
   1. Row & Columns
   ========================================================================== */

.grid { @include row; }

.grid > :not(.break) { @include col-base; }
.grid > .break { @extend .clearfix; }

/* 1.1. Widths
   This section produce classes (order is important because it's mobile first
   implementation - xs is applied to all sizes, sm to all expect xs etc.):
   xs-1, xs-2, ..., xs-n (where n is the $grid-columns),
   sm-1, sm-2, ..., sm-n,
   md-1, md-2, ..., md-n,
   lg-1, lg-2, ..., lg-n
   ========================================================================== */

$prefixes: xs, sm, md, lg;
@each $prefix in $prefixes {
	@include gte($prefix) {
		@for $i from 1 through $grid-columns {
			.grid > .#{$prefix}-#{$i} {
				@include col-size($i, $grid-columns);
			}
		}
	}
}

/* 1.2. Offsets
   ========================================================================== */
@each $prefix in $prefixes {
   @include gte($prefix) {
      @for $i from 1 through $grid-columns {
         .grid > .#{$prefix}-offset-#{$i} {
            margin-left: ($i / $grid-columns) * 100%;
         }
      }
   }
}

/* ==========================================================================
   2. Modifiers
   ========================================================================== */

/* 2.1. -no-gutter
   ========================================================================== */

.grid.-no-gutter { margin: 0; }
.grid.-no-gutter > :not(.break) { padding: 0; }

/* 2.2. -flex
   ========================================================================== */

.grid.-flex {
    display: flex;
    flex-wrap: wrap;
}