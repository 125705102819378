.regulations {
  ol {
    &.regulations-list {
      list-style-type: decimal;
      & > li {
        @extend .mbm;
        font-weight: bold;
        p, ol {
          font-weight: normal;
        }
        .decimal, .disc {
          padding-left: 20px;
        }
        .decimal {
          list-style-type: decimal;
        }
        .disc {
          list-style-type: disc;
        }
      }
    }
  }
}