/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */
 
.menu {
	.menu {
		display: none;
		margin-top: $menu-gutter;
	}
}

.menu-toggle {
	margin-left: $menu-toggle-margin;

	&:before {
		@extend .text-center;
		@extend .text-middle;
		@extend .material-icons;
		content: "\E315"; // keyboard_arrow_right
		margin-left: -$menu-toggle-margin;
		width: $menu-toggle-margin;
		transition: transform 0.2s;
	}

	&.-collapsed:before {
		transform: rotate(90deg);
	}

	> span {
		@extend .font-bold;
		@extend .text-upper;
		@extend .text-middle;
		cursor: pointer;
	}

	+ .menu-link,
	+ .menu-toggle { margin-top: $menu-gutter; }
}

.menu-link {
	> a {
		@extend .font-small;
		display: block;
		color: $gray-dark;
		&:hover { color: $second; }
	}

	&.active > a { color: $primary; }

	+ .menu-link,
	+ .menu-toggle { margin-top: $menu-gutter; }
}

