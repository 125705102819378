/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.topnav-second {
	height: $topnav-height-second;
	transition: margin $topnav-fixed-duration;

	.to-right {
		// Prevent height changes when cart is moving down
		height: $topnav-height-second;
	}
}

.topnav-second .topnav-group {
	vertical-align: top;
	display: inline-block;

	> li { display: inline-block; }
}

.topnav-second .topnav-link {
	color: $gray-light;
	font-weight: $font-weight-bold;
	font-size: $font-size-small;
	text-transform: uppercase;
	line-height: $topnav-height-second;

	&:hover { color: $gray-neutral; }
	&.active { color: $primary; }
}

.topnav-second .topnav-link .material-icons {
	font-size: $font-size-large;
}