/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * "Poznaj naszą ofertę"
 */

.p-products {
	@include gte(md) {
		margin: 0 auto;
		text-align: center;
	}

	.p-products-text {
		@extend .mtl;
	}

	.p-products-item {
		max-width: $container-width / 3;

		.p-products-item-title {
			@extend .font-bold;
			@extend .font-large;
			@extend .font-light;
			@extend .text-upper;
		}

		.p-products-item-price {
			@extend .font-bold;
			@extend .font-primary;
			@extend .font-giant;
			@extend .text-upper;
		}

		.p-products-item-subtitle {
			@extend .mts;
			@extend .font-bold;
			@extend .text-upper;
		}

		.p-products-item-text {
			@extend .mts;
			@extend .font-neutral;
		}
	}
}