/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 * 
 * Summary:
 * 
 *  1. Item box
 *  2. Item fields
 */
 
.pricing {
	text-align: center;
}

/* ==========================================================================
   1. Item box
   ========================================================================== */

.pricing-item {
	@extend .mbm;
	display: inline-block;
	vertical-align: middle;
	padding: $space-md;
	margin: 0 $space-sm;
	background: white;
	box-shadow: 0 1px 3px rgba(black, 0.3);
	width: 220px;
	transition: all ease-in-out 0.3s;
	border-top: 4px solid transparent;

	@include media(null, 3 * $pricing-width-max + 3 * 2 * $space-sm) {
		display: block;
		margin: 0 auto;

		+ .pricing-item { margin-top: $space-md; }
	}

	&:hover {
		transform: scale(1.1);
		z-index: 90;
		border-top: 4px solid $primary;
	}
}

.pricing-item.-primary {
	padding: 35px;
	border-top: 4px solid $primary;
}

/* ==========================================================================
   2. Item fields
   ========================================================================== */

.pricing-item-name {
	@extend .font-bold;
	@extend .font-huge;
	@extend .font-primary;
}

.pricing-item-params {
	@extend .font-small;
}

.pricing-item-label {
	@extend .font-bold;
	@extend .font-tiny;
	@extend .font-light;
	@extend .text-upper;
}

.pricing-item-price {
	@extend .font-bold;
	@extend .font-large;
}

.pricing-item-price.-alt {
	@extend .font-normal;
	@extend .font-primary;
}

.pricing-server {
	min-height: 400px;
}

@media (min-width: $md) {
	.pricing-item {
		min-height: 400px;
	}

	.rel {
		position: relative;
	}
}

#pricing-hide-if-null {
	&:before {
		content: none;
	}
}
