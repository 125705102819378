/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */
 
.p-feature {

}

.p-feature-icon {
	float: left;

	> .material-icons {
		@extend .rounded;
		@extend .font-large;
		@extend .text-center;
		padding: $space-sm;
		border: 1px solid $gray-dark;
		line-height: 1;
	}
}

.p-feature-text {
	margin-left: 60px;
}