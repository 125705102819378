/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Summary:
 *
 *	1. Base
 *    - 1.1. Color
 *    - 1.2. Font
 *    - 1.3. Mobile
 *	2. Elements
 *	   - 2.1. Spacing
 *    - 2.2. Typography
 *    - 2.3. Button
 *    - 2.4. Link
 *    - 2.5. Container
 * 3. Collections
 *    - 3.1. Grid
 *    - 3.2. Topnav
 *    - 3.3. Footer
 *    - 3.4. Banner
 *    - 3.5. Form
 *    - 3.6. Table
 *    - 3.7. Menu
 *    - 3.8. Pricing
 *    - 3.9. Promo
 *    - 3.10. Dropdown
 *    - 3.11. Tabs
 *    - 3.12. Sidenav
 *    - 3.13. Toast
 *    - 3.14. Modal
 *    - 3.15. Blog
 */

/* ==========================================================================
   1. Base
   ========================================================================== */

/* 1.1. Color
   ========================================================================== */

$primary: $green;
$second:  $orange;

$bg-gray: #f3f3f3;

/* 1.2. Font
   ========================================================================== */

$font-weight-regular: 400;
$font-weight-bold:    700;

$font-size-tiny:   13px;
$font-size-small:  14px;
$font-size-normal: 15px;
$font-size-large:  18px;
$font-size-huge:   24px;
$font-size-giant:  32px;

$font-size:   $font-size-normal;
$font-color:  $gray-dark;
$font-family: Roboto, Open Sans, OpenSans, Arial;
$font-weight: $font-weight-regular;
$font-height: 1.4;

$link-color-normal: $green;
$link-color-hover:  $orange;
$link-color-active: $primary;

/* 1.3. Mobile
   ========================================================================== */

$sm: 480px;
$md: 720px;
$lg: 960px;

/* ==========================================================================
   2. Elements
   ========================================================================== */

/* 2.1. Spacing
   ========================================================================== */

$space-xs: 5px;
$space-sm: 10px;
$space-md: 20px;
$space-lg: 40px;

/* 2.2. Typography
   ========================================================================== */

$h1-bar-width: 360px;
$h1-bar-color-dark: rgba($gray-dark, 0.1);
$h1-bar-color-light: rgba($white, 0.1);
$h1-bar-margin: $space-sm;
$h1-bar-height: 2px;

/* 2.3. Button
   ========================================================================== */

$button-height-small:  32px;
$button-height-normal: 40px;
$button-height-large:  42px;
$button-color-normal:  $link-color-normal;
$button-color-hover:   $link-color-hover;
$button-border:        2px;

/* 2.4. Link
   ========================================================================== */

$link-height-small:  $button-height-small;
$link-height-normal: $button-height-normal;
$link-height-large:  $button-height-large;
$link-color-normal:  $button-color-normal;
$link-color-hover:   $button-color-hover;

/* 2.5. Container
   ========================================================================== */

$container-width: 1080px;
$container-padding: $space-md;

/* ==========================================================================
   3. Collections
   ========================================================================== */

/* 3.1. Grid
   ========================================================================== */

$grid-columns: 12;
$grid-gutter: 2 * $container-padding;

/* 3.2. Topnav
   ========================================================================== */

$topnav-background: white;

$topnav-fixed-height: 550px; // Minimum height to use fixed nav
$topnav-fixed-duration: 0.6s;

$topnav-breakpoint: 850px;
$topnav-breakpoint-mobile-second: sm;
$topnav-breakpoint-mobile-primary: sm;

$topnav-height-second: 30px; // desktop (top bar)
$topnav-height-primary: 70px; // desktop (bottom bar)
$topnav-height-mobile: 50px;

$topnav-menu-offset: 0px; // <= 0
$topnav-menu-breakpoint-image: md; // Hide image on <= viewport
$topnav-menu-breakpoint-promo: sm; // Hide promo on <= viewport
$topnav-menu-padding-mobile-second: 4px;
$topnav-menu-padding-mobile-primary: 6px;

$topnav-cart-move: $space-xs;
$topnav-cart-badge-offset: 2px;
$topnav-cart-badge-offset-mobile: 12px;
$topnav-cart-badge-size: 13px;

/* 3.3. Footer
   ========================================================================== */

$footer-height: 350px;
$footer-sticky-breakpoint: md; // Use sticky footer for >= viewport
$footer-color-opacity: 0.9;
$footer-link-padding: 3px;

/* 3.4. Banner
   ========================================================================== */

$banner-min-height: 400px;

/* 3.5. Form
   ========================================================================== */

$form-elements-radius: 2px;
$form-elements-padding: $space-sm;
$form-elements-height: $button-height-normal;
$form-elements-disabled-opacity: 0.4;

$form-error-offset: 2px;
$form-error-trangle-size: 4px;

$form-option-size: 14px;

/* 3.6. Table
   ========================================================================== */

$table-padding-v: $space-xs * 3 / 2;
$table-padding-h: $grid-gutter / 2;

/* 3.7. Menu
   ========================================================================== */

$menu-gutter: 7px;
$menu-toggle-margin: 30px;

/* 3.8. Pricing
   ========================================================================== */

$pricing-width-min: 180px;
$pricing-width-max: 220px;

/* 3.9. Promo
   ========================================================================== */

$promo-max-width: 600px;
$promo-max-height: 350px;

/* 3.10. Dropdown
   ========================================================================== */

$dropdown-max-height: 400px;
$dropdown-min-width: 120px;
$dropdown-max-width: 300px;
$dropdown-offset: 5px;

/* 3.11. Tabs
   ========================================================================== */

$tabs-h-padding: $space-md;
$tabs-link-height: $form-elements-height;

/* 3.12. Sidenav
   ========================================================================== */

$sidenav-width: 360px; // update also in src/js/_sidenav.js
$sidenav-padding: $grid-gutter / 2;
$sidenav-bg-color: white;
$sidenav-logo-offset: -6px;
$sidenav-top-height: 40px;
$sidenav-profile-menu-offset: -5px;
$sidenav-toggle-visibility: 1300px; // 1300px to support most popular resolution (1366x768). Also recommended: $container-width + $sidenav-width (show sidenav when main section cannot grow more).

/* 3.13. Toast
   ========================================================================== */

$toast-offset: $space-md;
$toast-icon-width: 40px;
$toast-prefered-width: 420px;

/* 3.14. Modal
   ========================================================================== */

$modal-bg-opacity: 0.8;
$modal-box-offset: $space-md $space-sm;
$modal-box-radius: 2px;
$modal-close-offset: 4px;
$modal-close-size: 24px;

/* 3.15. Blog
   ========================================================================== */

$blog-item-width: 480px;
$blog-item-breakpoint: 540px; // should be greater than item width

/* 3.16. Article (e.g. blog article)
   ========================================================================== */

$article-box-width: 600px;
$article-banner-height: 480px;
$article-banner-blur-size: 20px;
$article-banner-border-width: 10px;