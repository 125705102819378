/**
 * Version: 1.1.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Changelog:
 *
 * 1.1.0 - add "-blink" modifier
 */

.topnav-cart {
	position: relative;

	&.-blink {
		transition: none;
		transform: scale(1.1);
		background: $orange;
	}
}

.topnav-cart-badge {
	display: block;
	border-radius: 100%;
	font-weight: $font-weight-bold;
	font-size: 9px; // hardcoded
	background: $orange;
	position: absolute;
	top: $topnav-cart-badge-offset-mobile;
	right: $topnav-cart-badge-offset-mobile;
	min-width: $topnav-cart-badge-size;
	height: $topnav-cart-badge-size;
	line-height: $topnav-cart-badge-size;
	transition: transform 2.0s;
	text-align: center;
	padding: 0 3px;
	color: white;

	&.-blink {
		transition: none;
		transform: scale(1.8);
	}
}