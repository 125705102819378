/**
 * Version: 1.1.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Changelog:
 *
 * 1.1.0 - add scroll x/y element
 */

.to-left { float: left !important; }
.to-right { float: right !important; }

.scroll.-x { overflow-x: auto; }
.scroll.-y { overflow-y: auto; }