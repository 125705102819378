/*
Border ribbon
Element nadrzędny musi mieć ustawioną klasę .ribbon-box
*/


.ribbon-box {
  position: relative;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;

  &:before,
  &:after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
  }

  span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 7px 0;
    background-color: $blue;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 12px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }

}

.ribbon-top-left {
  top: -10px;
  left: -10px;

  &:before,
  &:after {
    border-top-color: transparent;
    border-left-color: transparent;
  }

  &:before {
    top: 0;
    right: 45px;
  }

  &:after {
    bottom: 45px;
    left: 0;
  }

  span {
    right: -2px;
    top: 34px;
    transform: rotate(-45deg);
  }
}
