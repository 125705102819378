/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Lower Than (lt).
 *
 * Example:
 *
 * body {
 *     @include lt(md) {
 *         // apply at sm
 *         background: red;
 *     }
 * }
 */

@mixin lt($media) {
	@if index($media, 'sm') { @include media(null, $sm - 1px) { @content; }}
	@else if index($media, 'md') { @include media(null, $md - 1px) { @content; }}
	@else if index($media, 'lg') { @include media(null, $lg - 1px) { @content; }}
}