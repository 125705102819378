/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Summary:
 *
 * 1. Sticky footer
 * 2. Style
 *    - 2.1. Links
 *    - 2.2. News
 *    - 2.3. Copyright
 */

/* ==========================================================================
   1. Sticky footer
   http://ryanfait.com/sticky-footer/
   ========================================================================== */

@include gte($footer-sticky-breakpoint) {
	html, body {
		height: 100%;
	}

	.footer-wrapper {
		min-height: 100%;
		margin-bottom: -$footer-height;

		&:after {
			content: " ";
			display: block;
			height: $footer-height;
		}
	}

}

/* ==========================================================================
   2. Style
   ========================================================================== */

.footer {
	position: relative;
	background: $gray-dark url(../img/market/world.png) center center no-repeat;
}

/* 2.1. Links
   ========================================================================== */

.footer-link {
	color: white;
	display: block;
	opacity: $footer-color-opacity;
	padding: $footer-link-padding 0;

	&.-header {
		@extend .font-bold;
		@extend .text-upper;
	}
}

/* 2.2. News
   ========================================================================== */

.footer-header {
	@extend .mbx;
	@extend .font-bold;
	@extend .text-upper;
	color: white;
	opacity: $footer-color-opacity;
}

.footer-article {
	@extend .mbm;
	@extend .font-light;
	@extend .font-small;
	display: block;

	&:hover {
		@extend .font-light;
		.footer-article-title { color: $link-color-hover; }
	}
}

.footer-article-title {
	@extend .font-normal;
	opacity: $footer-color-opacity;
	transition: color 0.3s;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	color: white;
}

.footer-article-date {
	@extend .font-small;
	@extend .font-neutral;
	@extend .text-right;
	display: block;
}

.footer-article-message {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* 2.3. Copyright
   ========================================================================== */

.footer-copyright-relative {
	position: relative;

	@include gte($footer-sticky-breakpoint) {
		min-height: $footer-height;
	}
}

.footer-copyright {
	@extend .ptx, .pbx;
	@extend .font-tiny;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	opacity: 0.4;
	color: white;

}