/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.topnav-menu {
	display: none;
	line-height: normal;
	position: absolute;
	left: 0; right: 0;
	background: white;
	box-shadow: 0 1px 4px rgba(black, 0.4);
	margin-top: $topnav-menu-offset;
	overflow: hidden;

	@include lte($topnav-menu-breakpoint-image) {
		min-height: auto !important;
	}
}

.topnav-menu-left {
	@include col-base(2 * $space-lg);
	@include col-size(1, 3);
	@include lte($topnav-menu-breakpoint-promo) {
		@include col-size(1, 1);
	}

	margin: $space-sm 0;

	a {
		display: block;
		color: $gray-dark;
		padding: $space-sm 0;

		&:hover { color: $second; }
		&.active { font-weight: $font-weight-bold; }
	}
}

.topnav-menu-right {
	@include col-base(2 * $space-lg);
	@include col-size(2, 3);
	@include lte($topnav-menu-breakpoint-promo) {
		display: none;
	}

	margin: $space-md 0;
	border-left: 1px solid rgba($gray-dark, 0.1);

	.topnav-menu-promo {
		@include lte($topnav-menu-breakpoint-image) {
			margin-right: 0 !important;
		}

		header {
			font-weight: $font-weight-bold;
			text-transform: uppercase;
		}
	}

	.topnav-menu-image {
		@include lte($topnav-menu-breakpoint-image) {
			display: none;
		}

		position: absolute;
		right: 0;

		&.-top { top: 0; }
		&.-bottom { bottom: 0; }
	}
}