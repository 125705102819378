/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.loader-area {
	position: relative;
}

.loader-cover {
	position: absolute;
	top: 0; bottom: 0;
	left: 0; right: 0;
	background-image: url(../img/icons/ajax-loader.gif);
	background-position: center center;
	background-repeat: no-repeat;
	z-index: 105;
}

.loader-cover.-white { background-color: rgba(white, 0.8); }
.loader-cover.-gray { background-color: rgba($bg-gray, 0.8); }