/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.sidenav {
	margin-left: -$sidenav-width;
	height: 100%;
	width: $sidenav-width;
	max-width: 100%;
	background: $sidenav-bg-color;
	padding: 0 $sidenav-padding;
	overflow-x: hidden;
	overflow-y: auto;
	position: fixed;
	z-index: 100;

	@include media($sidenav-toggle-visibility) {
		display: block !important;
		margin-left: 0px !important;
	}
}

.sidenav-offset {
	@include media($sidenav-toggle-visibility) {
		margin-left: $sidenav-width;
	}
}

.sidenav-open,
.sidenav-close {
	@extend .font-bold;
	cursor: pointer;
	@include media($sidenav-toggle-visibility) {
		display: none;
	}

	i {
		@extend .font-large;
	}

	span {
		@extend .font-tiny;
		@extend .text-upper;
	}
}

/* ==========================================================================
   1. Top
   ========================================================================== */

.sidenav-top {
	line-height: $sidenav-top-height;
}

.sidenav-logo {
	display: none;
	vertical-align: middle;
	margin-top: $sidenav-logo-offset;

	@include media($sidenav-toggle-visibility) {
		display: inline-block;
	}
}

.sidenav-close {
	display: inline-block;
	@include media($sidenav-toggle-visibility) {
		display: none;
	}
}

.sidenav-profile {
	@extend .to-right;
}

.sidenav-profile .dropdown-link {
	@extend .font-bold;
	@extend .font-neutral;
	@extend .font-small;
	@extend .text-upper;
	line-height: $sidenav-top-height;
}

.sidenav-profile .dropdown-menu {
	line-height: $font-height;
	margin-top: $sidenav-profile-menu-offset;
}

/* ==========================================================================
   2. Middle/Bottom
   ========================================================================== */

.sidenav-link a {
	@extend .ptx, .pbx;
	@extend .font-bold;
	@extend .font-neutral;
	@extend .text-upper;
	display: inline-block;
}

.sidenav-link.-current a { @extend .font-primary; }
.sidenav-link a:hover { @extend .font-second; }