/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.topnav-bar {
	height: $topnav-height-mobile;
	line-height: $topnav-height-mobile;
}

.topnav-bar .topnav-brand {
	float: left;
	font-size: 0rem;
	line-height: $topnav-height-mobile;
	max-height: $topnav-height-mobile;

	img {
		font-size: 0rem;
		vertical-align: middle;
	}
}

.topnav-bar .topnav-group {
	float: right;
	display: inline-block;
	height: $topnav-height-mobile;
	margin-right: -($grid-gutter / 2); // Remove right container padding

	> li { display: inline-block; }
}

.topnav-bar .topnav-link {
	float: left;
	width: $topnav-height-mobile;
	height: $topnav-height-mobile;
	font-size: $font-size-large;
	text-align: center;
	color: $gray-dark;
}