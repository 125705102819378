/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */
 
.panel {
	display: table;
	width: 100%;
}

.panel-shrink {
	vertical-align: middle;
	white-space: nowrap;
	display: table-cell;
	width: 1px;
}

.panel-grow {
	vertical-align: middle;
	display: table-cell;
	width: 100%;
}