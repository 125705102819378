/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.p-php7 {
	min-height: 340px;
	color: white;
	background: #84489c url(../img/market/banner/pattern.jpg) center center no-repeat;
	background-size: cover;
}

.p-php7-mark-area {
	position: relative;
}

.p-php7-mark-img {
	position: absolute;
	top: -$space-lg; right: 0;
}

.p-php7-content {
	max-width: $container-width / 2;
}