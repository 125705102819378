.sticky-banner {
    position: sticky;
    z-index: 99;
}

// Under topnav

@media all and (min-width: $topnav-breakpoint) {
    .sticky-banner.-under-topnav {
        transition: top $topnav-fixed-duration;
        top: $topnav-height-primary + $topnav-height-second;
    }

    .topnav.-small ~ .sticky-banner.-under-topnav {
        top: $topnav-height-primary;
    }
}

@media all and (max-width: $topnav-breakpoint - 1px) {
    .sticky-banner.-under-topnav {
        top: 0;
    }
}