.article-banner {
    height: $article-banner-height;
    position: relative;
    text-align: center;
    overflow: hidden;
}

.article-banner-blur {
    position: absolute;
    left: -$article-banner-blur-size;
    top: -$article-banner-blur-size;
    width: calc(100% + #{2 * $article-banner-blur-size});
    height: calc(100% + #{2 * $article-banner-blur-size});
    object-fit: cover;
    filter: blur($article-banner-blur-size);
    z-index: -1;
}

.article-banner-image {
    max-width: 100%;
    height: $article-banner-height;
    border-left: $article-banner-border-width solid $white;
    border-right: $article-banner-border-width solid $white;
    object-fit: cover;
}

.article-box {
    width: 100%;
    max-width: $article-box-width;
    padding: 0 $container-padding;
    margin: 0 auto;
}