/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */
 
.image {
	@extend .pts;
	@extend .pbs;
	@extend .prm;
	@extend .plm;
	position: relative;
	display: inline-block;
	height: 50px;
	img {
		height: 100%;
		display: block;
		margin: 0 auto;
	}
}

.image-front {
	max-width: 100%;
	backface-visibility: hidden;
	transition: all 0.5s ease-in-out;
	filter: grayscale(0.9);
	opacity: 0.4;
}

.image-back {
	max-width: 100%;
	filter: grayscale(0);
	opacity: 1;
	transform: rotateY(360deg);
	backface-visibility: hidden;
}

.slick-arrow {
	display: none !important;
}