/**
 * Version: 1.1.1
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Version:
 *
 * 1.1.0 - add disabled modifier
 * 1.1.1 - trim longer text to one line
 *
 * Usage:
 *
 * <tag class="link -<param>"> where:
 * - <param> - small/normal/large
 *
 * Examples: 
 *
 * <div class="link"> Link </div>
 * <a class="link -large"> Large Link </a>
 */

.link {
	@extend .-normal;
	@extend .font-bold;
	@extend .text-middle;
	@extend .text-center;
	@extend .text-upper;
	display: inline-block;
	transition: color 0.4s, border 0.4s;
	color: $link-color-normal;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100%;
	cursor: pointer;

	&:hover { color: $link-color-hover; }
}

.link.-small {
	font-size: ($font-size-tiny - 1px);
	height: $link-height-small;
	line-height: $link-height-small;
}

.link.-normal {
	font-size: ($font-size-normal - 1px);
	height: $link-height-normal;
	line-height: $link-height-normal;
}

.link.-large {
	font-size: ($font-size-large - 1px);
	height: $link-height-large;
	line-height: $link-height-large;
}

.link.-disabled {
	color: $gray-dark;
	opacity: $form-elements-disabled-opacity;
	cursor: default;
}