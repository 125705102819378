/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * "W komplecie taniej"
 */

.p-package {
	max-width: 400px;

	@include gte(md) {
		margin: 0 auto;
		text-align: center;
	}

	.p-package-subtitle {
		@extend .font-light;
		@extend .font-bold;
		@extend .text-upper;
	}

	.p-package-title {
		@extend .font-bold;
		@extend .font-huge;
		@extend .text-upper;
	}

	.p-package-text {
		@extend .mts;
		@extend .font-neutral;
	}

	.p-package-price {
		@extend .mtm;
		@extend .font-huge;
		@extend .text-upper;
		display: block;
	}
}