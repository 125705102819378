/**
 * Version: 1.1.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Versions:
 * 
 * 1.1.0 - new background (lake, girl), background fade color on mobile devices
 *
 * Summary:
 *
 *  1. Dots layer
 *  2. Backgrounds
 */

.banner {
	position: relative;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;

	@include gte(lg) {
		min-height: $banner-min-height;
	}
}

.banner-content {
	padding: ($banner-min-height / 4) 0;
	position: relative;
	z-index: 10;

	@include gte(lg) {
		width: 100%;
		max-width: ($container-width / 2) - ($grid-gutter / 2);
		float: right;
	}
}

.banner-title {
	@extend .font-bold;
	@extend .font-giant;
	@extend .text-upper;
}

.banner-subtitle {
	@extend .font-bold;
	@extend .font-huge;
	@extend .text-upper;

	strong {
		@extend .font-giant;
		@extend .font-primary;
	}
}

/* ==========================================================================
   1. Dots layer
   ========================================================================== */

.banner.-dark,
.banner.-light {
	&:after {
		content: " ";
		position: absolute;
		top: 0; bottom: 0;
		left: 0; right: 0;
		background-repeat: repeat;
	}
}

.banner.-dark:after { background-image: url(../img/icons/dot-dark.png); }
.banner.-light:after { background-image: url(../img/icons/dot-light.png); }

@include lt(lg) {
	.banner.-dark:after { background-color: rgba(black, 0.6); }
	.banner.-light:after { background-color: rgba(white, 0.6); }
}

/* ==========================================================================
   2. Backgrounds
   ========================================================================== */

.banner-bg-cafe { background-image: url(../img/market/banner/cafe.jpg); }
.banner-bg-lake { background-image: url(../img/market/banner/lake.jpg); }
.banner-bg-girl { background-image: url(../img/market/banner/girl.jpg); }
.banner-bg-blog { background-image: url(../img/market/banner/blog.jpg); }
.banner-bg-promo-pol-roku-na-boku { background-image: url(../img/market/banner/pol-roku-na-boku.jpg); }
.banner-bg-promo-zero-na-maksa { background-image: url(../img/market/banner/zero-na-maksa.jpg); }
.banner-bg-promo-kreator-www { background-image: url(../img/market/banner/kreator-stron-www.jpg); }
.banner-bg-data { background-image: url('../img/market/banner/data.jpg');}
.banner-bg-server-vps { background-image: url('../img/market/banner/server-vps.jpg');}

.banner-title {
	text-align: left;
}

.banner-title::after {
	content: none !important;
}