/**
 * Version: 1.1.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: none;
}

body, input, textarea, select, button {
	color: $font-color;
	font-size: $font-size;
	font-family: $font-family;
	line-height: $font-height;
}

aside, header, footer, main, section, article, code {
	display: block;
}

a {
	text-decoration: none;
	color: $link-color-normal;
	transition: color 0.4s;

	&:hover { color: $link-color-hover; }
	&.active { color: $link-color-active; }
	&:focus { color: $link-color-hover; }
}

a img {
	border: none; // IE9
}

strong, b, th {
	font-weight: $font-weight-bold;
}

ol, ul {
	list-style-type: none;

	&.-decimal { list-style-type: decimal; }
	&.-circle { list-style-type: circle; }
}

blockquote, q {
	quotes: none;

	&:before, &:after {
		content: '';
		content: none;
	}
}

small {
	font-size: $font-size-small;
}

*[id]:before { /* https://css-tricks.com/hash-tag-links-padding/ */
	display: block;
	content: " ";
	margin-top: -($topnav-height-primary + $topnav-height-second);
	height: ($topnav-height-primary + $topnav-height-second);
	visibility: hidden;
}

.modal,
[show-if-input],
[show-if-checked] {
	display: none;
}

video {
	max-width: 100%;
}