/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.badge {
	@extend .font-tiny;
	@extend .font-bold;
	background: $second;
	border-radius: 2px;
	padding: 1px 3px;
	color: white;
}