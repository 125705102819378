/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Changelog:
 *
 * 1.1.0 - add "-blink" modifier
 */

.topnav-cart {
	text-align: center;
	position: relative;
	display: inline-block;
	line-height: $topnav-height-second;
	width: $topnav-height-second;
	height: $topnav-height-second;
	transition: padding 0.3s, height 0.3s, margin $topnav-fixed-duration;
	background: lighten($primary, 10%);
	position: relative;
	color: white;
	z-index: 10;

	&:hover {
		color: white;
		padding-top: $topnav-cart-move;
		height: $topnav-height-second + $topnav-cart-move;
	}
}

.topnav-cart-badge {
	border-radius: 100%;
	font-weight: $font-weight-bold;
	font-size: 9px; // hardcoded
	position: absolute;
	background: $orange;
	right: $topnav-cart-badge-offset;
	min-width: $topnav-cart-badge-size;
	height: $topnav-cart-badge-size;
	line-height: $topnav-cart-badge-size;
	transition: transform 2.0s;
	text-align: center;
	padding: 0 3px;

	// We can not use 'top' property because your :hover state will
	// move whole icon except the badge (that would stay pinned to the top).
	bottom: $topnav-height-second - $topnav-cart-badge-offset - $topnav-cart-badge-size;

	&.-blink {
		transition: none;
		transform: scale(1.8);
	}
}

/*.topnav-cart:hover .topnav-cart-menu {
	display: block;
}

.topnav-cart-menu {
	color: $gray-dark;
	text-align: left;
	font-size: $font-size-tiny;
	
	// from .dropdown-menu
	display: none;
	cursor: default;
	position: absolute;
	background: white;
	box-shadow: 0 1px 2px rgba(black, 0.4);
	max-height: $dropdown-max-height;
	min-width: $dropdown-min-width;
	margin-top: $dropdown-offset;
	overflow-y: auto;
	max-width: $dropdown-max-width;
	z-index: 105;

	// from .dropdown-menu.right
	right: 0;

	> div {
		padding: $space-xs $space-sm;
		line-height: $font-height;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}*/