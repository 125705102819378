.dropdown-list {
  display: block;
  @extend .pts;
  @extend .pbs;
  @extend .text-left;
  margin-left: 0;

  &:before {
    @extend .font-green;
  }

  > span {
    @extend .font-large;
    @extend .font-green;
  }
  > ul {
    > li {
      @extend .pts;
      @extend .pbs;
      @extend .pll;
      width: 100%;
      margin: 0 auto;

      &:nth-child(even) {
        background-color: #c3c8cb42;
      }
    }
  }

  .menu-link {
    &:not(:last-child) {
      margin-bottom: $menu-gutter;
    }
  }
}
