/**
 * Version: 1.1.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Changelog:
 *
 * 1.1.0 - add "-loading" modifier
 *
 * Usage:
 *
 * <tag class="button -<param>"> where:
 * - <param> - small/normal/large
 *
 * Examples: 
 *
 * <div class="button"> Button </div>
 * <a class="button -large"> Large Button </a>
 */

.button {
	@extend .-normal;
	@extend .font-bold;
	@extend .text-middle;
	@extend .text-center;
	@extend .text-upper;
	display: inline-block;
	border: $button-border solid $button-color-normal;
	border-radius: $button-height-normal;
	transition: color 0.4s, border 0.4s;
	color: $button-color-normal;
	padding: 0 ($space-md * 3/2);
	cursor: pointer;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100%;

	&:hover {
		color: $button-color-hover;
		border-color: $button-color-hover;
	}
}

.button.-small {
	font-size: ($font-size-tiny - 1px);
	height: $button-height-small;
	line-height: ($button-height-small - 2*$button-border);
}

.button.-normal {
	font-size: ($font-size-normal - 1px);
	height: $button-height-normal;
	line-height: ($button-height-normal - 2*$button-border);
}

.button.-large {
	font-size: ($font-size-large - 1px);
	height: $button-height-large;
	line-height: ($button-height-large - 2*$button-border);
}

.button.-loading {
	background: url(../img/icons/ajax-loader.gif) center center no-repeat;
	border-color: $gray-neutral;
	color: transparent;
}