/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Summary:
 *
 *	1. Global
 *	   - 1.1. Weight
 *	   - 1.2. Size
 *     - 1.3. Color
 *     - 1.4. Transform
 *     - 1.5. Align
 *     - 1.6. Whitespace
 *	2. Headings
 */

/* ==========================================================================
   1. Global
   ========================================================================== */

/* 1.1. Weight
   ========================================================================== */

.font-regular { font-weight: $font-weight-regular; }
.font-bold    { font-weight: $font-weight-bold; }

/* 1.2. Size
   ========================================================================== */

.font-tiny   { font-size: $font-size-tiny; }
.font-small  { font-size: $font-size-small; }
.font-normal { font-size: $font-size-normal; }
.font-large  { font-size: $font-size-large; }
.font-huge   { font-size: $font-size-huge; }
.font-giant  { font-size: $font-size-giant; }

/* 1.3. Color
   ========================================================================== */

.font-light   { color: $gray-light; }
.font-neutral { color: $gray-neutral; }
.font-dark    { color: $gray-dark; }

.font-blue   { color: $blue; }
.font-orange { color: $orange; }
.font-green  { color: $green; }
.font-white  { color: white; }

.font-primary { color: $primary; }
.font-second  { color: $second; }

/* 1.4. Transform
   ========================================================================== */

.text-upper { text-transform: uppercase; }
.text-lower { text-transform: lowercase; }

/* 1.5. Align
   ========================================================================== */

.text-left    { text-align: left; }
.text-center  { text-align: center; }
.text-justify { text-align: justify; }
.text-right   { text-align: right; }

.text-top    { vertical-align: top; }
.text-middle { vertical-align: middle; }
.text-bottom { vertical-align: bottom; }

/* 1.6. Whitespace
   ========================================================================== */

.text-nowrap { white-space: nowrap; }

/* ==========================================================================
   2. Headings
   ========================================================================== */

h1, .h1 {
	@extend .font-bold;
	@extend .font-huge;
	@extend .text-upper;
	@extend .text-center;

	/* Border bottom */
	&:after {
		margin: 0 auto;
		content: " ";
		display: block;
		overflow: hidden;
		margin-top: $h1-bar-margin;
		background: $h1-bar-color-dark;
		max-width: $h1-bar-width;
		height: $h1-bar-height;
	}
}

.font-white h1, h1.font-white {
	&:after {
		background: $h1-bar-color-light;
	}
}

h2, .h2 {
	@extend .font-bold;
	@extend .font-large;
}

h3, .h3 {
	@extend .font-regular;
	@extend .font-large;
}
