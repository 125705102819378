/**
 * Version: 1.1.1
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Changelog:
 * 
 * 1.0.1 - fix select and file input clickable area
 * 1.1.0 - add tooltip
 * 1.1.1 - fix bug with too long text in select/file input
 *
 * Summary:
 *
 *	1. Basic
 *     - 1.1. Label
 *     - 1.2. Input
 *     - 1.3. Password
 *     - 1.4. File
 *     - 1.5. Text
 *     - 1.6. Checkbox
 *     - 1.7. Radio
 *     - 1.8. Select
 *     - 1.9. Button
 *     - 1.10. Error
 *	2. Advance
 *     - 2.1. Group
 *     - 2.2. Field
 */

/* ==========================================================================
   1. Basic
   ========================================================================== */

$form-border-height: 1px;

/* 1.1. Label
   ========================================================================== */

.form-label {
	@extend .font-bold;
	display: inline-block;
	padding: $space-sm $form-elements-padding;
}

.form-label-tooltip {
	@extend .mls;
	@extend .font-large;
	line-height: 1;
	float: right;
	color: $blue;
}

.form-label.-required:after {
	content: "*";
	color: $orange;
}

/* 1.2. Input
   ========================================================================== */

.form-input {
	color: $gray-dark;
	padding: 0 $form-elements-padding;
	height: $form-elements-height;
	border-radius: $form-elements-radius;
	border: $form-border-height solid $gray-light;
	transition: border-color 0.4s;
	background: white;
	max-width: 100%;
	width: 100%;

	&:focus { border-color: $second; }
	&:disabled { opacity: $form-elements-disabled-opacity; }
}

/* 1.3. Password
   ========================================================================== */

.form-password {
	@extend .form-input;
}

/* 1.4. File
   ========================================================================== */

.form-file {
	display: block;
	overflow: hidden;
	position: relative;

	> input {
		width: 0;
		height: 0;
		z-index: -1;
		float: left;
		opacity: 0;
	}

	> label {
		@extend .form-input;
		line-height: $form-elements-height - 2*$form-border-height;
		display: block;
		padding: 0;

		> span {
			display: block;
			padding-left: $form-elements-padding;
			margin-right: $form-elements-height;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	> input:focus + label { border-color: $second; }
	> input:disabled + label { opacity: $form-elements-disabled-opacity; }

	.material-icons {
		@extend .font-large;
		//position: absolute;
		//top: 0; right: 0;
		width: $form-elements-height - 2*$form-border-height; // 2px - border top and bottom
		height: $form-elements-height - 2*$form-border-height; // 2px - border top and bottom
		line-height: $form-elements-height - 2*$form-border-height; // 2px - border top and bottom
		text-align: center;
		margin-top: 0;
		float: right;
	}
}

/* 1.5. Text
   ========================================================================== */

.form-text {
	@extend .form-input;
	padding-top: $space-sm;
	padding-bottom: $space-sm;
	resize: vertical;
	min-height: $form-elements-height * 4;
}

/* 1.6. Checkbox
   ========================================================================== */

.form-checkbox {
	display: inline-block;
	margin-left: $form-elements-padding;

	> input {
		// We cannot use display none,
		// because browsers (at least Chrome)
		// doesn't focus on such input.
		float: left;
		opacity: 0;
	}

	> label {
		display: block;
		margin-left: $form-option-size + $space-sm;

		&:before {
			float: left;
			display: block;
			content: " ";
			width: $form-option-size;
			height: $form-option-size;
			background: url(../img/icons/form.png) 0 0 no-repeat;
			margin-left: -($form-option-size + $space-sm);
			margin-top: 3px;
		}

		> .tooltip {
			@extend .mls;
			@extend .font-large;
			line-height: 1;
			float: right;
			color: $blue;
		}
	}

	> input:checked + label:before { background-position: 0 (-$form-option-size); }
	> input:focus + label:before { background-position: 0 (2 * -$form-option-size); }
	> input:focus:checked + label:before { background-position: 0 (3 * -$form-option-size); }
	> input:disabled + label:before { opacity: $form-elements-disabled-opacity; }
}

/* 1.7. Radio
   ========================================================================== */

.form-radio {
	@extend .form-checkbox;
	
	> label:before { background-position: (-$form-option-size) 0; }
	> input:checked + label:before { background-position: (-$form-option-size) (-$form-option-size); }
	> input:focus + label:before { background-position: (-$form-option-size) (2 * -$form-option-size); }
	> input:focus:checked + label:before { background-position: (-$form-option-size) (3 * -$form-option-size); }
	> input:disabled + label:before { opacity: $form-elements-disabled-opacity; }
}

/* 1.8. Select
   ========================================================================== */

.form-select {
	display: block;
	overflow: hidden;
	position: relative;

	> select {
		position: absolute;
		width: 100%;
		height: $form-elements-height;
		opacity: 0;
		color: $gray-dark;
	}

	> label {
		@extend .form-input;
		line-height: $form-elements-height - 2*$form-border-height;
		display: block;
		padding: 0;

		> span {
			display: block;
			padding-left: $form-elements-padding;
			margin-right: $form-elements-height;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	> select:focus + label { border-color: $second; }
	> select:disabled + label { opacity: $form-elements-disabled-opacity; }

	.material-icons {
		@extend .font-large;
		width: $form-elements-height - 2*$form-border-height;
		height: $form-elements-height - 2*$form-border-height;
		line-height: $form-elements-height - 2*$form-border-height;
		text-align: center;
		margin-top: 0;
		float: right;
	}
}

/* 1.9. Button
   ========================================================================== */

.form-button {
	@extend .button;
	background: transparent;

	&:focus {
		color: $second;
		border-color: $second;
	}
}

.form-button.-simple {
	border: none;
	padding-left: 0;
	padding-right: 0;
	color: $gray-dark;

	&:hover,
	&:focus {
		color: $second;
	}
}

/* 1.10. Error
   ========================================================================== */

.form-error {
	@extend .font-tiny;
	display: inline-block;
	background: $orange;
	padding: $space-xs $form-elements-padding;
	border-radius: $form-elements-radius;
	margin-top: $form-error-trangle-size + $form-error-offset;
	position: relative;
	color: white;

	&:before {
		width: 0;
		height: 0;
		content: " ";
		display: block;
		position: absolute;
		top: 0; left: $form-elements-padding;
		margin-top: -$form-error-trangle-size;
		border-left: $form-error-trangle-size solid transparent;
		border-right: $form-error-trangle-size solid transparent;
  		border-bottom: $form-error-trangle-size solid $orange;
	}
}

/* ==========================================================================
   2. Advance
   ========================================================================== */

/* 2.1. Group
   ========================================================================== */

.form-group {
	border: none;

	> legend {
		@extend .font-bold;
		@extend .font-large;
		@extend .font-neutral;
		@extend .text-upper;
		@extend .text-center;
		width: 100%; // Edge
	}
}

/* 2.2. Field
   ========================================================================== */

.form-field {
	display: block;
	+ .form-field { @extend .mts; }
}