/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.blog {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.blog-item {
    display: flex;
    flex-direction: column;
    flex: 0 0 $blog-item-width;
    box-shadow: 0 2px 6px rgba(black, 0.2);
    border-radius: 2px;
    overflow: hidden;
    margin: $space-md $space-md;

    @media all and (max-width: $blog-item-breakpoint) {
        flex: 1 0 100%;
    }
}

.blog-item-image {
    flex: 0 0 auto;
    display: block;
    width: 100%;
    height: 280px;
    overflow: hidden;

    img {
        width: inherit;
        height: inherit;
        object-fit: cover;
        transition: transform 0.9s;
    }
}

.blog-item:hover {
    .blog-item-image img {
        transform: scale(1.1);
    }
}

.blog-item-category {
    @extend .mbs;
    @extend .font-light;
    @extend .font-bold;
    @extend .font-small;
    text-transform: uppercase;
    text-align: center;
}

.blog-item-header {
    flex: 1 0 auto;
    @extend .mtm;
    @extend .mlm;
    @extend .mrm;
    @extend .mbm;

    h2 a {
        @extend .font-dark;
    }

    p {
        @extend .mts;
    }
}

.blog-item-footer {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    @extend .mlm;
    @extend .mrm;
    @extend .mbm;
    @extend .font-small;
}

.blog-item-footer-stats {
    display: flex;
    align-items: center;
    @extend .mrs;
}

.blog-item-social {
    font-size: 0;
    @extend .mlx;

    img {
        width: 22px;
        height: 22px;
    }
}

.blog-item-comments-count {
    flex: 0 0 auto;
    @extend .font-light;
    @extend .mrs;
}