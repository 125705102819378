/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * http://nicolasgallagher.com/micro-clearfix-hack/
 */

.cf, .clearfix {
	&:before,
	&:after {
	    content: " ";
	    display: table;
	}

	&:after {
	    clear: both;
	}
}
