/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.modal-outer {
	display: table;
	position: fixed;
	top: 0; left: 0;
	width: 100%;
	height: 100%;
	background: rgba(black, $modal-bg-opacity);
}

.modal-inner {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	width: inherit;
	height: inherit;
	padding: $modal-box-offset;
}

.modal-box {
	position: relative;
	display: inline-block;
	text-align: left;
	border-radius: $modal-box-radius;
	background: white;
	box-shadow: 0 1px 3px rgba(black, 0.6);
	overflow-y: auto;
	max-height: 100%;
}

.modal-box.-fit {
	width: 100%;
	height: 100%;
}

.modal-box-close {
	cursor: pointer;
	position: absolute;
	top: $modal-close-offset;
	right: $modal-close-offset;
	width: $modal-close-size;
	height: $modal-close-size;
	line-height: $modal-close-size;
	text-align: center;
	transition: color 0.3s;
	border-radius: 100%;

	&:hover { color: $link-color-hover; }

	i { vertical-align: baseline; }
}