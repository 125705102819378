/**
 * Version: 1.0.1
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Versions:
 *
 * 1.0.1 - fix dropdown width
 */

.dropdown {
	display: inline-block;
	position: relative;
}

.dropdown-link{
	transition: color 0.4s;

	&:hover {
		color: $link-color-hover;
		cursor: pointer;
	}
}

.dropdown-menu {
	display: none;
	cursor: default;
	position: absolute;
	background: white;
	box-shadow: 0 1px 2px rgba(black, 0.4);
	max-height: $dropdown-max-height;
	min-width: $dropdown-min-width;
	margin-top: $dropdown-offset;
	overflow-y: auto;
	max-width: $dropdown-max-width;
	z-index: 105;
}

.dropdown-menu.-right { right: 0; }
.dropdown-menu.-left { left: 0; }