/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.container {
	width: 100%;
	max-width: $container-width;
	padding: 0 $container-padding;
	margin: 0 auto;
}