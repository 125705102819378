.hosting-packages {
  display: block;
  font-size: $font-size-small;
  thead {
    background-color: $green;
    color: $white;
  }

  tr {
    &:nth-child(even) {
      &:not(.lead):not(.hosting-order) {
        @extend .bg-gray;
      }
    }

    &.lead {
      @extend .font-bold;
      border-top: 2px solid $green;
      &.hosting-promo {
        td {
          @extend .font-large;
        }
      }
    }

  }

  tbody {
    overflow: auto;
  }

  td {
    padding: 10px 15px;
    min-width: 140px;
    &:first-child {
      padding: 10px 0 10px 10px;
    }

    &:not(:first-child) {
      @extend .text-center;
    }
  }
  .tooltip-right {
    cursor: default;
  }

  .hosting-link {
    @extend .font-bold;
  }
}

.scroll-buttons {
  div {
    display: inline-block;
    i {
      font-size: 40px;
    }
  }

}
