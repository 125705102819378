/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.breadcrumb {
	@extend .font-tiny;
}

.breadcrumb-item {
	&:before {
		@extend .font-light;
		@extend .mlx, .mrx;
		content: " / ";
		display: inline-block;
	}

	&:first-child:before { display: none; }
}

.breadcrumb-item.-current {
	@extend .font-bold;
}