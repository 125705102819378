/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Usage:
 *
 * .<x><y><z> where:
 * - <x> - m/p (margin/padding)
 * - <y> - t/r/b/l (top/right/bottom/left)
 * - <z> - x/s/m/l (xs/sm/md/lg)
 *
 * Examples: 
 *
 * .mbs - Margin Bottom Small
 * .prl - Padding Right Large
 *
 * Summary:
 *
 *	1. Margin
 *	2. Padding
 */

/* ==========================================================================
   1. Margin
   ========================================================================== */

.mtx { margin-top: $space-xs !important; }
.mrx { margin-right: $space-xs !important; }
.mbx { margin-bottom: $space-xs !important; }
.mlx { margin-left: $space-xs !important; }

.mts { margin-top: $space-sm !important; }
.mrs { margin-right: $space-sm !important; }
.mbs { margin-bottom: $space-sm !important; }
.mls { margin-left: $space-sm !important; }

.mtm { margin-top: $space-md !important; }
.mrm { margin-right: $space-md !important; }
.mbm { margin-bottom: $space-md !important; }
.mlm { margin-left: $space-md !important; }

.mtl { margin-top: $space-lg !important; }
.mrl { margin-right: $space-lg !important; }
.mbl { margin-bottom: $space-lg !important; }
.mll { margin-left: $space-lg !important; }

/* ==========================================================================
   2. Padding
   ========================================================================== */

.ptx { padding-top: $space-xs !important; }
.prx { padding-right: $space-xs !important; }
.pbx { padding-bottom: $space-xs !important; }
.plx { padding-left: $space-xs !important; }

.pts { padding-top: $space-sm !important; }
.prs { padding-right: $space-sm !important; }
.pbs { padding-bottom: $space-sm !important; }
.pls { padding-left: $space-sm !important; }

.ptm { padding-top: $space-md !important; }
.prm { padding-right: $space-md !important; }
.pbm { padding-bottom: $space-md !important; }
.plm { padding-left: $space-md !important; }

.ptl { padding-top: $space-lg !important; }
.prl { padding-right: $space-lg !important; }
.pbl { padding-bottom: $space-lg !important; }
.pll { padding-left: $space-lg !important; }
