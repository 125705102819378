.markdown {
    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }

    ul li,
    ol li {
        margin-left: $space-lg;
    }

    p + ul,
    p + ol,
    li + li {
        margin-top: $space-xs;
    }

    ul + p,
    p + pre,
    pre + p,
    table + p,
    p + table,
    blockquote + p {
        margin-top: $space-sm;
    }

    h1 + *,
    h2 + *,
    h3 + *,
    h4 + *,
    h5 + *,
    h6 + * {
        margin-top: $space-sm;
    }

    h1,
    h2,
    h3 {
        margin-top: $space-lg;
    }

    h4,
    h5,
    h6 {
        margin-top: $space-md;
    }

    p + p,
    p + blockquote {
        margin-top: $space-sm;
    }

    h1 {
        text-align: left;
        border-bottom: none;
        text-transform: none;

        &:after {
            display: none;
        }
    }

    blockquote {
        font-style: italic;
        color: $gray-neutral;
        padding-left: 15px;
        position: relative;
        padding-top: 5px;
        padding-bottom: 5px;
        border-left: 2px solid $h1-bar-color-dark;

        &:before {
            content: "\"";
            position: absolute;
            font-size: 20px;
            left: 5px;
            top: 0px;
        }
    }

    code {
        display: inline-block;
        background: rgba(black, 0.08);
        border-radius: 3px;
        padding: 1px 4px;
        font-size: 0.9em;
    }

    pre code {
        padding: 4px 8px;
    }

    hr {
        width: 80%;
        height: 1px;
        border: none;
        background: $h1-bar-color-dark;
        margin: $space-lg auto;
    }

    img {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    table,
    table td,
    table th {
        border-bottom: 1px solid $h1-bar-color-dark;
        border-collapse: collapse;
    }

    table td,
    table th {
        padding: 6px 8px;
    }
}