section.transfer {
  min-height: calc(100vh - 450px);
  ul {
    list-style-type: initial;
  }
}

.transfer-login {
  min-height: 290px;
}

.transfer-register {
  @extend .transfer-login;
  p {
    text-align: left;
  }
}

.table-summary {
  thead {
    tr {
      border-bottom: 2px solid #43a047;
    }
  }
  th {
    @extend .font-bold;
  }
}