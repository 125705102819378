/**
 * Version: 1.0.1
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Changelog:
 *
 * 1.0.1 - fix compact modifier
 */
 
.table {
	border-collapse: collapse;
	border-spacing: 0;
}

.table th,
.table td {
	padding: $table-padding-v $table-padding-h;
    margin: 0 (-$table-padding-h);
}

.table.fluid:not(.-bordered) {
    width: calc(100% + #{2 * $table-padding-h}) !important;
}

.table thead th a {
	color: $gray-dark; // sorting asc/desc
}

/* ==========================================================================
   1. Modifiers
   ========================================================================== */

/* 1.1. Compact
   ========================================================================== */

.table.-compact th, 
.table.-compact td {
	padding: ($table-padding-v /2) ($table-padding-h / 2);
}

/* 1.2. Bordered
   ========================================================================== */

.table.-bordered td {
	border-top: 1px solid rgba($gray-neutral, 0.1);
}

/* 1.3. No-gutter
   ========================================================================== */

.table.-no-gutter th,
.table.-no-gutter td,
.table th.-no-gutter,
.table td.-no-gutter {
	padding: $table-padding-v 0;
}

/* ==========================================================================
   2. Responsive
   ========================================================================== */

@include lte(xs) {
	.table.xs-responsive {
		thead { display: none; }
		tfoot, tr + tr {
			display: block;
			margin-top: $space-lg;
		}

		th, td {
			display: block;
			text-align: left;

			&:before {
				color: $gray-neutral;
				font-size: $font-size-tiny;
				font-weight: $font-weight-bold;
				text-transform: uppercase;
				content: attr(data-col);
				display: block;
			}
		}
	}
}

@include lte(sm) {
	.table.sm-responsive {
		thead { display: none; }
		tfoot, tr + tr {
			display: block;
			margin-top: $space-lg;
		}

		th, td {
			display: block;
			text-align: left;

			&:before {
				color: $gray-neutral;
				font-size: $font-size-tiny;
				font-weight: $font-weight-bold;
				text-transform: uppercase;
				content: attr(data-col);
				display: block;
			}
		}
	}
}

@include lte(md) {
	.table.md-responsive {
		thead { display: none; }
		tfoot, tr + tr {
			display: block;
			margin-top: $space-lg;
		}

		th, td {
			display: block;
			text-align: left;

			&:before {
				color: $gray-neutral;
				font-size: $font-size-tiny;
				font-weight: $font-weight-bold;
				text-transform: uppercase;
				content: attr(data-col);
				display: block;
			}
		}
	}
}