/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.topnav-menu {
	display: none;
	position: absolute;
	top: $topnav-height-mobile; left: 0;
	width: 100%;
	background: $gray-dark;
	overflow-y: auto;
	padding-bottom: $space-sm;
	z-index: 101;
}

.topnav-menu .topnav-second {
	margin-top: $space-sm;
	text-align: right;

	@include gte($topnav-breakpoint-mobile-second) {
		> li { display: inline-block; }
	}
}

.topnav-menu .topnav-second .topnav-link {
	color: $gray-light;
	font-weight: $font-weight-bold;
	font-size: $font-size-small;
	text-transform: uppercase;
	padding: $topnav-menu-padding-mobile-second ($grid-gutter / 2);
	display: block;

	&.active { background: rgba(white, 0.1); }
}

.topnav-menu .topnav-primary {
	@include col-base(0);
	@include col-size(1, 1);
	@include gte($topnav-breakpoint-mobile-second) {
		@include col-size(1, 2);
	}
}

.topnav-menu .topnav-primary .topnav-link {
	color: white;
	display: block;
	padding: $topnav-menu-padding-mobile-primary ($grid-gutter / 2);
	margin-left: $space-md;

	&.active { background: rgba(white, 0.1); }
}

.topnav-menu .topnav-primary .topnav-link.-header {
	margin-top: $space-sm;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
	margin-left: 0;
}