/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

$orange: #ff5722;
$green:  #43a047;
$blue:   #2196f3;
$white:   white;

$gray-light:   #c3c8cb;
$gray-neutral: #848e93;
$gray-dark:    #39474e;
