/**
 * Version: 1.1.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.p-search {
	position: relative;
	max-width: $container-width / 3;
	margin: 0 auto;
}

.p-search input {
	@extend .form-input;
	@extend .rounded;
	padding-left: $space-md;
	padding-right: $form-elements-height;
}

.p-search button {
	@extend .form-button, .-simple;
	@extend .text-center;
	width: $form-elements-height;
	position: absolute;
	top: 0; right: 0;
}