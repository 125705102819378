/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.hide { display: none !important; }
.invisible { visibility: hidden !important; }
.fluid {
	width: 100% !important;
	max-width: 100% !important;
}

@include eq(xs) { 
	.xs-hide { display: none !important; }
	.xs-invisible { visibility: hidden !important; }
	.xs-fluid {
		width: 100% !important;
		max-width: 100% !important;
	}
}

@include eq(sm) {
	.sm-hide { display: none !important; }
	.sm-invisible { visibility: hidden !important; }
	.sm-fluid {
		width: 100% !important;
		max-width: 100% !important;
	}
}

@include eq(md) {
	.md-hide { display: none !important; }
	.md-invisible { visibility: hidden !important; }
	.md-fluid {
		width: 100% !important;
		max-width: 100% !important;
	}
}

@include eq(lg) {
	.lg-hide { display: none !important; }
	.lg-invisible { visibility: hidden !important; }
	.lg-fluid {
		width: 100% !important;
		max-width: 100% !important;
	}
}