/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 *
 * Summary:
 *
 *  1. Dots layer
 *  2. Backgrounds
 */

.promo {
	@extend .font-bold;
	@extend .font-large;
	max-width: $promo-max-width;
	max-height: $promo-max-height;
	position: relative;
	border-radius: 2px;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	display: block;

	b, strong {
		@extend .font-huge;
	}
}

.promo {
	.promo-content {
		@extend .text-upper;
		@extend .text-center;
		transition: transform 0.4s;
		padding: $space-xs $space-sm;
		position: relative;
		z-index: 10;
	}

	&:hover .promo-content {
		transform: scale(1.05);
		cursor: pointer;
	}
}

/* ==========================================================================
   1. Dots layer
   ========================================================================== */

.promo.-dark,
.promo.-light {
	&:after {
		content: " ";
		position: absolute;
		top: 0; bottom: 0;
		left: 0; right: 0;
		background-repeat: repeat;
	}
}

.promo.-dark:after { background-image: url(../img/icons/dot-dark.png); }
.promo.-light:after { background-image: url(../img/icons/dot-light.png); }

/* ==========================================================================
   2. Backgrounds
   ========================================================================== */

.promo.-dark { background-color: #27312c; }
.promo.-light { background-color: #e9e9e7; }

.promo.-bg-lake { background-image: url(../img/market/promo/lake.jpg); }
.promo.-bg-girl { background-image: url(../img/market/promo/girl.jpg); }

/* ==========================================================================
   3. Accent
   ========================================================================== */

.promo.-light { color: $gray-dark; }
.promo.-light .promo-accent {
	@extend .font-primary;
}

.promo.-dark { color: white; }
.promo.-dark .promo-accent {
	border-top: 2px solid $primary;
	border-bottom: 2px solid $primary;
}