.creator-build {
  min-height: 230px;
  .tabs-links {
    @extend .text-center;
  }
  .tabs-link {
    display: block;
  }

  .tabs-content {
    min-height: 230px;

    .icon {
      @extend .mbm;
      font-size: 50px;
    }
  }
}

.modules {
  div {
    @extend .mbs;
    text-align: center;
  }
}

.creator-req {
  min-height: 200px;
}

.creator-req-container {
  @extend .font-bold;
}

.creator-func {
  & > div {
    min-height: 140px !important;
  }
}

@media (min-width: $md) {
  .creator-build {
    .tabs-links {
      margin-top: 10px;
      margin-bottom: 40px;
    }
    .tabs-link {
      display: inline-block;
      position: relative;
      width: 15%;
      height: 60px;
      margin-right: 4%;
      background-color: $green;
      color: $gray-dark;
      line-height: 60px;
      padding-left: 35px;
      transition: all 0.4s ease-in-out;

      &.-active {
        color: $white;
      }

      &:not(.-active) {
        &:hover {
          color: #f0f0f0;
        }
      }
      &:before {
        content: "";
        position: absolute;
        right: -15px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 15px solid $green;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 15px solid white;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
      }
    }
  .tabs-content {
    padding: 40px;
  }
  }
  .modules {
    div {
      margin-bottom: 30px !important;
    }
  }



}