/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

.topnav {
	z-index: 99; // nav menu has 100 or 101
	background: $topnav-background;

	@media all and (max-width: $topnav-breakpoint - 1px) {
		.topnav-desktop { display: none !important; }
		.topnav-mobile {
			@import 'mobile/topnav-cart';
			@import 'mobile/topnav-bar';
			@import 'mobile/topnav-menu';
		}
	}

	@media all and (min-width: $topnav-breakpoint) {
		.topnav-mobile { display: none !important; }
		.topnav-desktop {
			@import 'desktop/topnav-second';
			@import 'desktop/topnav-cart';
			@import 'desktop/topnav-primary';
			@import 'desktop/topnav-menu';
		}
	}
}

@import 'topnav-fixed';