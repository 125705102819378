/**
 * Version: 1.0.0
 * Author: Wojciech Mleczek <mleczek.wojtek@gmail.com>
 */

/*code,
.code {
	@extend .mts, .mbs;
	@extend .font-tiny;
	padding: $space-sm $space-md;
	background: rgba($gray-light, 0.2);
	box-shadow: 0 1px 2px rgba(black, 0.2);
	white-space: pre;
}*/